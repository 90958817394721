<template>
  <a-modal
    v-model="addPlaceVisible"
    :title="(isAdd ? '添加' : '修改') + '广告位'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="addplace_modal"
    :width="700"
  >
    <div class="addplace_modal_content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item label="应用" prop="appId">
          <a-select v-if="!isAdd" :disabled="!isAdd" v-model="query.appId" placeholder="请选择应用">
            <a-select-option v-for="item in appList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
          <m-select-one
            v-else
            v-model="query.appId"
            class="col_item"
            label="应用"
            allowClear
            :searchById="true"
            hasOs
            :hasPosition="false"
            :allData="appList"
            @change="changeAppId"
          />
        </a-form-model-item>
        <a-form-model-item prop="position" label="广告样式">
          <a-select v-model="query.position" placeholder="请选择广告样式" @change="changePosition">
            <a-select-option v-for="item in positionList" :key="item.id" :value="item.id">
              {{
                item.name
              }}<m-tips
                v-if="item.isAbandon"
                :content="`SDK版本大于2.7.2推荐使用原生混合样式。`"
                :title="''"
                :width="200"
              ></m-tips>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="广告位名称" prop="name">
          <a-input v-model.trim="query.name" autocomplete="off" placeholder="请输入广告位名称" />
        </a-form-model-item>
        <a-form-model-item label="状态" prop="sts">
          <a-radio-group v-model="query.sts" @change="onChangeSwitch($event)">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <div class="more-setting" v-if="+isAdmin !== 2">
          <a-divider>高级配置</a-divider>
          <a-button
            @click="isExpend = !isExpend"
            type="link"
          ><a-icon :type="isExpend ? 'down' : 'up'" />{{ isExpend ? '收起' : '展开' }}</a-button
          >
        </div>
        <template v-if="isExpend">
          <!-- 测试广告 -->
          <a-form-model-item label="测试广告" prop="testAd">
            <a-radio-group v-model="query.testAd">
              <a-radio value="1"> 开启 </a-radio>
              <a-radio value="0"> 关闭 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="auditSts" v-if="+isAdmin !== 2">
            <m-tips
              slot="label"
              :content="`素材审核状态开启后，需要对该广告位下绑定的API预算进行素材审核，只有审核通过的素材才可以投放出去。`"
              :title="'素材审核状态'"
              :width="200"
            ></m-tips>
            <a-radio-group v-model="query.auditSts">
              <a-radio value="A"> 开启 </a-radio>
              <a-radio value="S"> 关闭 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="query.position === 5 && +isAdmin !== 2" label="服务端激励回调" prop="serverVerify">
            <a-switch size="small" :checked="query.serverVerify === 1" @change="handleServerVerify" />
          </a-form-model-item>
          <a-form-model-item
            v-if="query.serverVerify === 1 && query.position === 5 && +isAdmin !== 2"
            label="激励名称"
            prop="rewardName"
          >
            <a-input v-model.trim="query.rewardName" autocomplete="off" placeholder="请输入激励名称" />
          </a-form-model-item>
          <a-form-model-item
            v-if="query.serverVerify === 1 && query.position === 5 && +isAdmin !== 2"
            label="激励数量"
            prop="rewardCount"
          >
            <a-input-number
              style="width: 200px"
              v-model.trim="query.rewardCount"
              autocomplete="off"
              placeholder="请输入激励数量"
            />
          </a-form-model-item>
          <a-form-model-item
            v-if="query.serverVerify === 1 && query.position === 5 && +isAdmin !== 2"
            label="回调URL"
            prop="callbackUrl"
          >
            <a-input v-model.trim="query.callbackUrl" autocomplete="off" placeholder="请输入回调URL" />
          </a-form-model-item>
          <a-form-model-item
            v-if="query.serverVerify === 1 && query.position === 5 && +isAdmin !== 2"
            label="安全密钥"
            prop="secKey"
          >
            <a-input :disabled="true" v-model.trim="query.secKey" autocomplete="off" placeholder="请输入安全密钥" />
          </a-form-model-item>
          <a-form-model-item v-if="query.position === 1 && skip === 1 && +isAdmin !== 2" label="是否跳过" prop="skip">
            <a-radio-group v-model="query.skip">
              <a-radio value="A"> 开启 </a-radio>
              <a-radio value="S"> 关闭 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="视频静音播放" prop="videoMutePlayback">
            <a-radio-group v-model="query.videoMutePlayback">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="+isAdmin !== 2" label="单设备展示上限（小时）" prop="impressFreqHour">
            <a-input
              @change="handleInputProp($event, 'impressFreqHour')"
              v-model.trim="query.impressFreqHour"
              autocomplete="off"
              placeholder="不限"
            />
          </a-form-model-item>
          <a-form-model-item v-if="+isAdmin !== 2" label="单设备展示上限（天）" prop="impressFreqDay">
            <a-input
              @change="handleInputProp($event, 'impressFreqDay')"
              v-model.trim="query.impressFreqDay"
              autocomplete="off"
              placeholder="不限"
            />
          </a-form-model-item>
          <a-form-model-item v-if="+isAdmin !== 2" label="单设备展示间隔（秒）" prop="impressInterval">
            <a-input
              @change="handleInputProp($event, 'impressInterval')"
              v-model.trim="query.impressInterval"
              autocomplete="off"
              placeholder="不限"
            />
          </a-form-model-item>
        </template>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { getAllAppPage, getMaterialTemplateList, placeUpdate, addPlace } from '@/api/media'
import { mapState } from 'vuex'
import { getPositionList } from '@/api/common'
import md5 from 'js-md5'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      isExpend: false,
      tempQuery: {
        appId: undefined,
        appName: '',
        auditSts: 'S',
        cacheTime: 1,
        testAd: '0',
        createTime: '',
        divideIntoProportions: 100,
        id: '',
        name: '',
        os: 1,
        platNum: 1,
        position: undefined,
        skip: 'S',
        sts: 'A',
        templateIds: [],
        rewardName: undefined,
        rewardCount: 0,
        callbackUrl: undefined,
        serverVerify: 0,
        level: 1,
        impressFreqHour: undefined,
        impressFreqDay: undefined,
        impressInterval: undefined,
        videoMutePlayback: 1
      },
      query: {},
      rules: {
        appId: [{ required: true, message: '请选择应用' }],
        name: [{ required: true, message: '请输入广告位名称', trigger: 'blur' }],
        position: [{ required: true, message: '请选择广告样式', trigger: 'change' }]
      },
      appList: [],
      positionList: [],
      templateList: [],
      appObj: {},
      positionObj: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    appId: {
      default: '',
      type: String
    },
    addAppId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({
        appId: '',
        appName: '',
        auditSts: 'S',
        cacheTime: 1,
        createTime: '',
        divideIntoProportions: 100,
        id: '',
        name: '',
        os: 1,
        testAd: '0',
        platNum: 1,
        position: undefined,
        skip: 'S',
        sts: 'A',
        templateIds: [],
        rewardName: undefined,
        rewardCount: 0,
        callbackUrl: undefined,
        serverVerify: 0,
        level: 1
      }),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val && this.isAdd) {
          this.query = this.tempQuery
        } else if (!this.isAdd && val) {
          this.query = { ...this.query, ...JSON.parse(JSON.stringify(this.placeInfo)) }
        }
      },
      immediate: true,
      deep: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    },
    // 新增时,把应用带进来
    addAppId: {
      async handler (val) {
        if (val && this.isAdd && this.visible) {
          await this.getAllAppPage()
          this.query.appId = val
          this.appObj = this.appList.filter((child) => child.id === val)
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getPositionList()
    this.getMaterialTemplateList()
  },
  created () {
    this.getAllAppPage()
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      skip: (state) => state.user.skip,
      role: (state) => state.user.roles[0],
      mediaAccess: (state) => state.user.mediaAccess
    }),
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    onChangeSwitch (e) {},
    changeApp (e) {
      this.query.appId = e
    },
    handleInputProp (e, prop) {
      if (+e.target.value < 1) {
        this.query[prop] = undefined
      } else if (+e.target.value >= 1) {
        this.query[prop] = Math.ceil(+e.target.value)
      } else {
        this.query[prop] = undefined
      }
    },
    changeAppId (val) {
      if (this.isAdd) {
        this.appObj = this.appList.filter((child) => child.id === val)
        if (this.appObj.length > 0 && this.positionObj.length > 0) {
          const os = this.appObj[0].os ? '安卓' : 'IOS'
          this.$set(this.query, 'name', this.appObj[0].name + '-' + os + '-' + this.positionObj[0].name)
        }
      }
    },
    changePosition (val) {
      this.query.videoMutePlayback = val === 5 ? 0 : 1
      if (this.isAdd) {
        const md = md5('' + new Date().getTime(), this.query.appId ? this.query.appId : '')
        this.query.secKey = md
        this.positionObj = this.positionList.filter((child) => child.id === val)
        if (this.appObj.length > 0 && this.positionObj.length > 0) {
          const os = this.appObj[0].os ? '安卓' : 'IOS'
          this.query.name = this.appObj[0].name + '-' + os + '-' + this.positionObj[0].name
        }
      }
    },
    handleServerVerify (e) {
      this.query.serverVerify = e ? 1 : 0
      if (!this.isAdd) {
        const md = md5('' + new Date().getTime(), this.query.appId ? this.query.appId : '')
        this.query.secKey = md
      }
    },
    handleChangeSts (sts) {
      this.query.auditSts = sts ? 'A' : 'S'
    },
    async getMaterialTemplateList () {
      const resp = await getMaterialTemplateList()
      this.templateList = resp.data || []
    },
    async getPositionList () {
      const { data = [] } = await getPositionList()
      data.forEach((item) => {
        item.name = item.isAbandon ? `${item.name}（已废弃）` : item.name
      })
      this.positionList = data
    },
    async getAllAppPage () {
      const resp = await getAllAppPage()
      this.appList = resp?.data[0] || []
    },
    handleCancel () {
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const resp = await addPlace(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changePlace')
              this.addPlaceVisible = false
            }
          } else {
            const resp = await placeUpdate(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changePlace')
              this.addPlaceVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.addplace_modal .ant-modal-body {
  .addplace_modal_content {
    max-height: 550px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    overflow-y: auto;
    .more-setting {
      width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10%;
    }
  }
}
</style>
